import { FormGroup } from '@mui/material'
import { MBlock, MFieldInput } from '@mprise/react-ui'
import { Field, useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { AlertDialog, AlertType } from '../../shared/alert-dialog'
import { FieldArraySwitch } from '../../shared/field-switch'
import { FormikCompareFix } from '../../shared/formik'
import { AutocompleteFieldSimple, CheckboxField } from '../../shared/material-ui-formik'
import { MFieldConnector } from '../../shared/mfield-adapter'
import { MFieldProviderConnector } from '../../shared/mfield-provider-adapter'
import { MSection, MSections } from '../../shared/msection'
import { PatternDialog } from '../../shared/pattern-dialog'
import { Maybe, nameof } from '../../shared/typescript'
import { yupEnumArray, yupObject } from '../../shared/yup-common-types'
import { WorkItemType, WorkResultType, WorkItemTemplateTaskOption, AutoStopPercOptionGh } from '../../lib/enums'
import { AvailableResultTypes } from '../work-item-template/restrictions'

export interface GHTaskForm {
  order: number
  name: string
  types: Array<WorkResultType>
  taskOptions: Array<WorkItemTemplateTaskOption>
  taskOptionsTransfer: Array<WorkItemTemplateTaskOption>
  taskOptionsGeneral: Array<WorkItemTemplateTaskOption>
  taskOptionsIC: Array<WorkItemTemplateTaskOption>
  settingFinishOnFulfilled: Maybe<boolean>
  autoStopTaskOption: Maybe<AutoStopPercOptionGh>
}

export const GHTaskDialog = ({
  alert,
  templateType,
  title,
  label,
  open,
  onClose,
  onSave,
  initialValues,
  onAlertClose,
}: {
  alert?: AlertType | null
  templateType: WorkItemType
  title: React.ReactNode
  label: string
  open: boolean
  onClose: () => void
  onSave: (values: GHTaskForm & FormikCompareFix) => void
  initialValues: GHTaskForm & FormikCompareFix
  onAlertClose?: () => void
}) => {
  const schema = GHTaskDialog.useSchema()
  return (
    <PatternDialog
      initialValues={initialValues}
      schema={schema}
      onSave={onSave}
      title={title}
      submit={label}
      open={open}
      onClose={onClose}
    >
      {alert === `duplicate` && <AlertDialog alert={alert} entity={`Task`} handleClose={onAlertClose} />}
      <GHTaskDialogForm templateType={templateType} />
    </PatternDialog>
  )
}

const GHTaskDialogForm = ({ templateType }: { templateType: WorkItemType }) => {
  const { t } = useTranslation()

  const f = useFormikContext<GHTaskForm>()
  let resultTypes = []

  if (templateType === WorkItemType.JobWorkOrder) {
    // Add fake resultType CROP_MAINTENANCE which will be changed to JOB_INVENTORY_PUTAWAY on save template.
    resultTypes = [WorkResultType.ItemConsumption, WorkResultType.JobInventoryPutaway, WorkResultType.CropMaintenance]
  } else {
    resultTypes = AvailableResultTypes[templateType] ?? []
  }
  const showSettingFinishWhenFulfilled = [WorkItemType.JobPickOrder].includes(templateType)

  useEffect(() => {
    if (f.values.types?.length) {
      if (f.values.types.includes(WorkResultType.CropMaintenance) && f.values.types.length > 1) {
        // CROP_MAINTENANCE cannot be combined with itemConsumption or jobInventoryPutAway
        const indexOfCropMaintenance = f.values.types.indexOf(WorkResultType.CropMaintenance)
        if (indexOfCropMaintenance !== 0) {
          f.setFieldValue('types', [WorkResultType.CropMaintenance])
          // this taskOption is an indicator: when edit template, change types from JOB_INVENTORY_PUTAWAY to CROP_MAINTENANCE
          f.setFieldValue('taskOptionsGeneral', [WorkItemTemplateTaskOption.CropMaintenanceGh])
          f.setFieldValue('taskOptionsTransfer', [])
        } else {
          f.setFieldValue('types', [...f.values.types.slice(1)])
          f.setFieldValue('taskOptionsGeneral', [])
        }
      } else if (!f.values.types.includes(WorkResultType.ItemConsumption)) {
        f.setFieldValue('taskOptionsIC', [])
      }
    }

    // Currently, JobPickOrders MUST have WorkResultType.ItemOutput enabled
    if (templateType === WorkItemType.JobPickOrder && !f.values.types.includes(WorkResultType.ItemOutput)) {
      f.setFieldValue('types', [...f.values.types, WorkResultType.ItemOutput])
    }
  }, [f.values.types])

  useEffect(() => {
    const autoStopOption = f.values.taskOptionsGeneral.find(taskOption => taskOption.startsWith('AUTO_STOP'))
    if (autoStopOption) {
      // Change/remove taskOptions with AUTO_STOP from db to fieldValue autoStopTaskOption
      f.setFieldValue('autoStopTaskOption', autoStopOption)
      const taskOptionsGeneral = f.values.taskOptionsGeneral.filter(taskOption => taskOption !== autoStopOption)
      f.setFieldValue('taskOptionsGeneral', taskOptionsGeneral)
    }
  }, [f.values.taskOptionsGeneral])

  useEffect(() => {
    if (f.values.taskOptionsTransfer.length > 1) {
      f.setFieldValue('taskOptionsTransfer', [...f.values.taskOptionsTransfer.slice(1)])
    }
  }, [f.values.taskOptionsTransfer])

  return (
    <MSections>
      <MSection title={t(`Task`)}>
        <MBlock padding={4}>
          <Field component={MFieldConnector} name={nameof<GHTaskForm>(`name`)} fullWidth label={t(`Name`)}>
            <MFieldInput margin='dense' autoFocus inputProps={{ maxLength: 25 }} />
          </Field>
          {showSettingFinishWhenFulfilled && (
            <Field
              style={{ border: '1px solid' }}
              component={CheckboxField}
              name={nameof<GHTaskForm>(`settingFinishOnFulfilled`)}
              fullWidth
              label={t(`FIELD_FINISH_TASK_ON_FULFILLED`)}
              description={t(`FIELD_FINISH_TASK_ON_FULFILLED_DESCRIPTION`)}
            />
          )}
        </MBlock>
      </MSection>
      {resultTypes.length > 0 ? (
        <MSection title={t(`Select Task Results`)}>
          <MBlock padding={4}>
            <Field component={MFieldProviderConnector} name='types'>
              <FormGroup>
                {resultTypes.map((type, index) => (
                  <FieldArraySwitch
                    key={index}
                    label={t(`WorkResultType.${type}`)}
                    value={type}
                    disabled={templateType === WorkItemType.JobPickOrder}
                  />
                ))}
              </FormGroup>
            </Field>
          </MBlock>
        </MSection>
      ) : null}

      {templateType === WorkItemType.JobWorkOrder && showSettingsJobInventoryPutAway(f.values) ? (
        <MSection title={t('Transfer Options')}>
          <MBlock padding={4}>
            <Field component={MFieldProviderConnector} name='taskOptionsTransfer'>
              <FormGroup>
                <FieldArraySwitch
                  label={t('Move carriers to position')}
                  value={WorkItemTemplateTaskOption.MoveCarriersToLocationGh}
                />
                <FieldArraySwitch
                  label={t('Offload carrier to (bulk) position')}
                  value={WorkItemTemplateTaskOption.OffloadCarriersGh}
                />
                <FieldArraySwitch
                  label={t(`Put on carrier from (bulk) position`)}
                  value={WorkItemTemplateTaskOption.PutOnCarriersGh}
                />
                <FieldArraySwitch
                  label={t(`Advanced Area Registration`)}
                  value={WorkItemTemplateTaskOption.AdvancedAreaRegistrationGh}
                />
              </FormGroup>
            </Field>
          </MBlock>
        </MSection>
      ) : null}
      {templateType === WorkItemType.JobWorkOrder && showSettingsItemConsumption(f.values) ? (
        <MSection title={t('Task Options Item Consumption')}>
          <MBlock padding={4}>
            <Field component={MFieldProviderConnector} name='taskOptionsIC'>
              <FormGroup>
                <FieldArraySwitch
                  label={t('Auto Fill Item Consumption')}
                  value={WorkItemTemplateTaskOption.AutoFillConsumptionQuantityGh}
                />
                <FieldArraySwitch
                  label={t('Allow overruling Lot reservation')}
                  value={WorkItemTemplateTaskOption.AllowOverruleLotReservationGh}
                />
              </FormGroup>
            </Field>
          </MBlock>
        </MSection>
      ) : null}

      {templateType === WorkItemType.JobWorkOrder ? (
        <MSection title={t('General Options')}>
          <MBlock padding={4}>
            <Field component={MFieldProviderConnector} name='taskOptionsGeneral'>
              <FormGroup>
                {hasOptionAAR(f.values) ? (
                  <FieldArraySwitch
                    label={t(`Post Job Mutations through Work Order`)}
                    value={WorkItemTemplateTaskOption.BypassJobInventoryGh}
                  />
                ) : (
                  <FieldAutoStartStop />
                )}
              </FormGroup>
            </Field>
          </MBlock>
        </MSection>
      ) : null}
    </MSections>
  )
}

GHTaskDialog.useSchema = () => {
  const { t } = useTranslation()

  const [schema] = useState(() =>
    yupObject<GHTaskForm>({
      name: yup.string().label(t(`Name`)).required(),
      types: yupEnumArray(WorkResultType).optional().label(t(`Types`)) as any,
      taskOptions: yupEnumArray(WorkItemTemplateTaskOption).optional().label(t(`TaskOptions`)) as any,
      taskOptionsTransfer: yupEnumArray(WorkItemTemplateTaskOption).optional().label(t(`TaskOptions`)) as any,
      taskOptionsGeneral: yupEnumArray(WorkItemTemplateTaskOption).optional().label(t(`TaskOptions`)) as any,
      taskOptionsIC: yupEnumArray(WorkItemTemplateTaskOption).optional().label(t(`TaskOptions`)) as any,
      order: yup.number().required().label(t(`Order`)),
      settingFinishOnFulfilled: yup.boolean().nullable().label(t(`FIELD_FINISH_TASK_ON_FULFILLED`)),
      autoStopTaskOption: yup.string().nullable().label(t(`Auto stop`)),
    }),
  )

  return schema
}

const showSettingsJobInventoryPutAway = (values: GHTaskForm): boolean => {
  return (
    values.types.includes(WorkResultType.JobInventoryPutaway) && !values.types.includes(WorkResultType.ItemConsumption)
  )
}
const showSettingsItemConsumption = (values: GHTaskForm): boolean => {
  return values.types.includes(WorkResultType.ItemConsumption)
}

const hasOptionAAR = (values: GHTaskForm): boolean => {
  return values.taskOptionsTransfer.includes(WorkItemTemplateTaskOption.AdvancedAreaRegistrationGh)
}

const FieldAutoStartStop = () => {
  const { t } = useTranslation()
  const f = useFormikContext<GHTaskForm>()

  const showAutoStopOptions = (values: GHTaskForm): boolean => {
    return values.taskOptionsGeneral.includes(WorkItemTemplateTaskOption.AutoStartStopTasksGh)
  }

  const getAutoStopDescription = (s: AutoStopPercOptionGh) => {
    return t(s)
  }

  return (
    <>
      <FieldArraySwitch label={t(`Auto StartStop Tasks`)} value={WorkItemTemplateTaskOption.AutoStartStopTasksGh} />
      {showAutoStopOptions(f.values) && (
        <Field
          component={AutocompleteFieldSimple}
          name='autoStopTaskOption'
          label={t(`Complete task`)}
          options={Object.values(AutoStopPercOptionGh)}
          getOptionLabel={getAutoStopDescription}
          showClearIcon={false}
          defaultValue={AutoStopPercOptionGh.AutoStopPerc100Gh}
        />
      )}
    </>
  )
}
