import React, { Suspense, useContext, useState } from 'react'
import { MpriseAuthProvider, MpriseIdentityServer } from '@mprise/react-auth'
import { /*MpriseMuiThemeProvider,*/ MpriseTheme /*MpriseMuiThemeOptions*/ } from '@mprise/react-ui'
import { MpriseMuiThemeProvider } from './theme'
import * as ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import './lib/translations/i18n'
import { AppLayout } from './route/layout'
import { AppRoutes } from './route/routes'
import { Splash } from './splash'
import { StateLocale } from './state/locale'
import { StateSession } from './state/session'
import { StateSettings } from './state/settings'
import { NoSsr } from '@mui/material'
import { ErrorBoundary } from './error-boundary'
import { ApolloClient, createHttpLink, InMemoryCache, ApolloProvider } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

const startup = async () => {
  const root = ReactDOM.createRoot(document.getElementById('root')!)

  root.render(
    <React.StrictMode>
      <MpriseTheme />
      <MpriseMuiThemeProvider>
        <ErrorBoundary>
          <NoSsr>
            <BrowserRouter>
              <StateSettings>
                <StateSession>
                  <StateLocale>
                    <Root />
                  </StateLocale>
                </StateSession>
              </StateSettings>
            </BrowserRouter>
          </NoSsr>
        </ErrorBoundary>
      </MpriseMuiThemeProvider>
    </React.StrictMode>,
  )
}

const Root = () => {
  const settings = useContext(StateSettings.Context)
  const [mpriseId] = useState(() => new MpriseIdentityServer(settings.login, settings.clientId))

  const fetchTokenFromStorage = () => {
    const token = sessionStorage.getItem('oidc.user:' + settings.login + ':' + settings.clientId)

    if (token) {
      const tokenInfo = JSON.parse(token)
      return tokenInfo['access_token']
    } else {
      return ''
    }
  }

  const httpLink = createHttpLink({
    uri: settings.gateway,
  })

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = fetchTokenFromStorage()
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    }
  })

  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            trackingIds: {
              keyArgs: ['filter'],
              merge: mergeFunctionWithPageAndTotal,
            },
            jobs: {
              keyArgs: ['filter'],
              merge: mergeFunctionWithPageAndTotal,
            },
            lots: {
              keyArgs: ['filter'],
              merge: mergeFunctionWithPageAndTotal,
            },
            carrierIds: {
              keyArgs: ['filter'],
              merge: mergeFunctionWithPageAndTotal,
            },
            resources: {
              keyArgs: ['filter'],
              merge: mergeFunctionWithPageAndTotal,
            },
            items: {
              keyArgs: ['filter'],
              merge: mergeFunctionWithPageAndTotal,
            },
            activities: {
              keyArgs: ['filter'],
              merge: mergeFunctionWithPageAndTotal,
            },
            containers: {
              keyArgs: ['filter'],
              merge: mergeFunctionWithPageAndTotal,
            },
            reasons: {
              keyArgs: ['filter'],
              merge: mergeFunctionWithPageAndTotal,
            },
            positions: {
              keyArgs: ['filter'],
              merge: mergeFunctionWithPageAndTotal,
            },
            users: {
              keyArgs: ['filter'],
              merge: mergeFunctionWithPageAndTotal,
            },
          },
        },
      },
    }),
  })

  return (
    <MpriseAuthProvider mpriseId={mpriseId}>
      <ApolloProvider client={apolloClient}>
        {/* <MpriseGatewayApolloClient batch schema={schema} gatewayUrl={settings.gateway} typePolicies={typePolicies} possibleTypes={GatewayFragment.possibleTypes}> */}
        <Suspense fallback={<Splash />}>
          <Splash>
            <AppLayout>
              <AppRoutes />
            </AppLayout>
          </Splash>
        </Suspense>
        {/* </MpriseGatewayApolloClient> */}
      </ApolloProvider>
    </MpriseAuthProvider>
  )
}

const mergeFunctionWithPageAndTotal = (existing: any, incoming: any, { args }: any) => {
  const merged = existing ? existing.page.slice(0) : []
  for (let i = 0; i < incoming?.page?.length; ++i) {
    merged[(args?.offset ?? 0) + i] = incoming.page[i]
  }
  return { page: merged, total: incoming.total }
}

const startTime = Date.now()
startup()
  .then(() => {
    const duration = Date.now() - startTime
    console.log(`Initialized in ${(duration / 1000).toFixed(1)}s`)
  })
  .catch(error => {
    console.error(`Startup error:`, error)
    const root = document.getElementById('root')
    if (root) {
      root.innerText = `Oops! ${String(error.message ?? error)}`
    }
  })
