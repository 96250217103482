import { gql } from '@apollo/client'

export const GET_SPACING_METHOD = gql`
  query SpacingMethod($filter: SpacingMethodFilter, $limit: Int, $offset: Int) {
    spacingMethods(filter: $filter, limit: $limit, offset: $offset) {
      page {
        id
        code
        description
        cosmosKey
      }
      total
    }
  }
`
