import { gql } from '@apollo/client'

export const GET_VARIANTS = gql`
  query Variants($filter: VariantFilter, $limit: Int, $offset: Int) {
    variants(filter: $filter, limit: $limit, offset: $offset) {
      page {
        id
        code
        description
        item {
          genus
        }
      }
      total
    }
  }
`
